import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import desc from 'img/boreal-images/desc.png'
import fill1 from 'img/boreal-images/fill1.jpg'
import group3 from 'img/boreal-images/group3.png'
import group12 from 'img/boreal-images/group12.jpg'
import group13 from 'img/boreal-images/group13.jpg'
import group16 from 'img/boreal-images/group16.jpg'
import image3boreal from 'img/boreal-images/image3-boreal.png'
import image4boreal from 'img/boreal-images/image4-boreal.png'
import mano from 'img/boreal-images/mano.png'
import mensajeexpress from 'img/boreal-images/mensaje-express.jpg'
import superexpress from 'img/boreal-images/superexpress.jpg'

type CompraBoletoProps = PageProps

interface BorealStudioState {
  mounted: boolean
}

class CompraBoleto extends Component<CompraBoletoProps, BorealStudioState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <ul>
            <li>
              <img src={desc} />
            </li>
            <li>
              <img src={fill1} />
            </li>
            <li>
              <img src={group3} />
            </li>
            <li>
              <img src={group12} />
            </li>
            <li>
              <img src={group13} />
            </li>
            <li>
              <img src={group16} />
            </li>
            <li>
              <img src={group12} />
            </li>
            <li>
              <img src={image3boreal} />
            </li>
            <li>
              <img src={image4boreal} />
            </li>
            <li>
              <img src={mano} />
            </li>
            <li>
              <img src={mensajeexpress} />
            </li>
            <li>
              <img src={superexpress} />
            </li>
          </ul>
        </AAnimate>
      </Layout>
    )
  }
}

export default CompraBoleto
